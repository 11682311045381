import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { createProducts, resetState } from "../features/product/productSlice";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { db } from "./firebaase.js";



let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  category: yup.string().required("Category is Required"),

});

const Addproduct = () => {
  const dispatch = useDispatch();
  const newProduct = useSelector((state) => state.product);
  const { isSuccess, isError, isLoading, createdProduct } = newProduct;
  const [productVideo, setProductVideo] = useState(null);
  const [productPhotos, setProductPhotos] = useState(new Array(5).fill(null));
  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [productSpecifications, setProductSpecifications] = useState('');


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const snapshot = await db.collection('categories').get();
        const categoryData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoryData);
      } catch (error) {
        console.error('Error fetching categories: ', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfullly!");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);




  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
      category: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {

      dispatch(createProducts(values));
      formik.resetForm();
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });

  const handleSpecificationsChange = (e) => {
    setProductSpecifications(e.target.value);
  };

  const handleFileChange = (e, index) => {
    const files = e.target.files;
    if (index >= 0 && index < 5) {
      const photosArray = [...productPhotos];
      photosArray[index] = files[0];
      setProductPhotos(photosArray);
    } else if (index === 5) {
      setProductVideo(files[0]);
    }
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (productPhotos.length === 0 ||
      !productVideo
    ) {
      console.error('Please fill in all the fields and upload at least one photo.');
      return;
    }

    try {
      const db = firebase.firestore();
      const storageRef = firebase.storage().ref();
      const categoryRef = db.collection('categories').doc(formik.values.category);
      const productRef = categoryRef.collection('products');


      const productSnapshot = await categoryRef.collection('products').get();
      const productCount = productSnapshot.size + 1;
      const productId = `Product${productCount}`;

      // Create a folder for each product in Firebase Storage
      const photoURLs = [];

      for (let index = 0; index < Math.min(5, productPhotos.length); index++) {
        const photo = productPhotos[index];
        const photoRef = storageRef.child(`product-photos/${productId}/photo${index + 1}`);
        const uploadTask = photoRef.put(photo);

        try {
          // Wait for the upload to complete
          await uploadTask;

          // Get the download URL for the photo
          const downloadURL = await photoRef.getDownloadURL();

          // Store the download URL in the array
          photoURLs.push(downloadURL);
        } catch (error) {
          console.error('Error uploading or getting download URL: ', error);
          // Handle the error as needed
        }
      }

      // Upload video to Firebase Storage
      const videoRef = storageRef.child(`product-videos/${productId}`);
      const videoTask = videoRef.put(productVideo);

      await new Promise((resolve, reject) => {
        videoTask.on(
          'state_changed',
          (snapshot) => {
            // Get the upload progress for the video
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Error uploading video: ', error);
            reject(error);
          },
          async () => {
            // Resolve after the video is successfully uploaded
            resolve();
          }
        );
      });

      // Get the download URL for the video
      const videoURL = await videoRef.getDownloadURL();

      // Split specifications into an array of objects
      const specificationsArray = productSpecifications
        .split('\n')
        .map((specification) => {
          const [name, value] = specification.split(':').map((item) => item.trim());
          return { name, value };
        });

      // Add product to Firestore
      await productRef.add({
        title: formik.values.title,
        price: parseFloat(formik.values.price),
        description: formik.values.description,
        photos: photoURLs,
        category: formik.values.category,
        videoURL: videoURL,
        specifications: specificationsArray
      });

      // Clear the input fields after successful submission
      setProductPhotos([]);
      setProductVideo(null);
      setUploadProgress(0);
      toast.success("Product Added Successfully");
      window.location.reload();
      console.log('Product added successfully!');
    } catch (error) {
      console.error('Error adding product: ', error);
    }
  };

  return (
    <div>
      <h3 className="mb-4 title">Add Product</h3>
      <div>
        <form
          onSubmit={handleFormSubmit}
          className="d-flex gap-3 flex-column"
        >
          <CustomInput
            type="text"
            label="Enter Product Title"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
          <div className="">
            <ReactQuill
              theme="snow"
              name="description"
              onChange={formik.handleChange("description")}
              value={formik.values.description}
            />
          </div>
          <div className="error">
            {formik.touched.description && formik.errors.description}
          </div>
          <CustomInput
            type="number"
            label="Enter Product Price"
            name="price"
            onChng={formik.handleChange("price")}
            onBlr={formik.handleBlur("price")}
            val={formik.values.price}
          />
          <div className="error">
            {formik.touched.price && formik.errors.price}
          </div>


          <select
            name="category"
            onChange={formik.handleChange("category")}
            onBlur={formik.handleBlur("category")}
            value={formik.values.category}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="" disabled>
              Select Category
            </option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <div className="error">
            {formik.touched.tags && formik.errors.tags}
          </div>


          <textarea
            theme="snow"
            name="productSpecifications"
            onChange={handleSpecificationsChange}
            value={productSpecifications}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setProductSpecifications((prev) => `${prev}\n`);
              }
            }}
          />
          <div className="error">
            {formik.touched.productSpecifications && formik.errors.productSpecifications}
          </div>


          {/* [photo] */}
          <div className="bg-white border-1 p-5 text-center" style={{ display: 'flex' }}>
            {productPhotos.map((photo, index) => (
              <label key={index} style={{ display: 'flex', fontStyle: 'italic' }}>
                Upload Photo {index + 1}:
                <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, index)} />
                {uploadProgress[index] > 0 && <p>Upload Progress: {uploadProgress[index]}%</p>}
              </label>
            ))}
          </div>

          {/* Video  */}
          <label>
            Upload Video:
            <input type="file" accept="video/*" onChange={(e) => handleFileChange(e, 5)} />
          </label>

          {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}

          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct;
