import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteAProductCategory,
  getCategories,
  resetState,
} from "../features/pcategory/pcategorySlice";
import CustomModal from "../components/CustomModal";
import firebase from 'firebase/compat/app';

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
    render: (text, record, index) => index + 1,

  },
  {
    title: "Name",
    dataIndex: "name",
    // sorter: (a, b) => a.name.length - b.name.length,
  },

 
];

const Categorylist = () => {
  const [open, setOpen] = useState(false);
  const [pCatId, setpCatId] = useState("");
  const [data1, setdata]= useState([]);

  const showModal = (e) => {
    setOpen(true);
    setpCatId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {

    const fetchData = async () => {
      try {
        const db = firebase.firestore();
        const querySnapshot = await db.collection("categories").get();
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            key: doc.id,
            name: doc.data().name,
            
          });
        });
        setdata(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    dispatch(resetState());
    dispatch(getCategories());
  }, []);
  // const pCatStat = useSelector((state) => state.pCategory.pCategories);
  // const data1 = [];
  // for (let i = 0; i < pCatStat.length; i++) {
  //   data1.push({
  //     key: i + 1,
  //     name: pCatStat[i].title,
  //     action: (
  //       <>
  //         <Link
  //           to={`/admin/category/${pCatStat[i]._id}`}
  //           className=" fs-3 text-danger"
  //         >
  //           <BiEdit />
  //         </Link>
  //         <button
  //           className="ms-3 fs-3 text-danger bg-transparent border-0"
  //           onClick={() => showModal(pCatStat[i]._id)}
  //         >
  //           <AiFillDelete />
  //         </button>
  //       </>
  //     ),
  //   });
  // }
  const deleteCategory = (e) => {
    dispatch(deleteAProductCategory(e));
    setOpen(false);
    setTimeout(() => {
      dispatch(getCategories());
    }, 100);
  };
  return (
    <div>
      <h3 className="mb-4 title">Product Categories</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          deleteCategory(pCatId);
        }}
        title="Are you sure you want to delete this Product Category?"
      />
    </div>
  );
};

export default Categorylist;
